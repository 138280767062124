<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">상담원 변경</v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="mt-3 pb-0 pl-7 pr-7 pt-3">
            <v-row no-gutters>
                <v-col class="pb-0 title" style="color:#999999">상담원 이름<span style="color:red; padding-left:5px;">*</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-4">
                    <v-text-field outlined hide-details :error="formStatus['user_name'].err" :ref="'user_name'" placeholder="이름을 입력해 주세요" v-model="formData.user_name"
                                  @keyup="checkChanged('user_name')" @change="checkChanged('user_name', true)">
                        <template v-slot:append v-if="formStatus['user_name'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['user_name'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="formData.device_type != 'LANDLINE'">
                <v-col class="pt-0 pb-0 title" style="color:#999999">휴대폰 번호<span style="color:red; padding-left:5px;">*</span></v-col>
            </v-row>
            <v-row no-gutters v-if="formData.device_type != 'LANDLINE'">
                <v-col class="pt-2 pb-4">
                    <v-text-field outlined hide-details :error="formStatus['user_mdn'].err" :ref="'user_mdn'" placeholder="휴대폰 번호를 입력해 주세요" v-model="formData.user_mdn"
                                  @keyup="checkChanged('user_mdn')" @change="checkChanged('user_mdn', true)" readonly>
                        <template v-slot:append v-if="formStatus['user_mdn'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['user_mdn'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-0 pb-0 title" style="color:#999999">비고</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-0">
                    <v-text-field outlined hide-details :error="formStatus['mng_memo'].err" :ref="'mng_memo'" placeholder="상담원에 대한 비고를 입력해 주세요" v-model="formData.mng_memo"
                                  @keyup="checkChanged('mng_memo')" @change="checkChanged('mng_memo', true)">
                        <template v-slot:append v-if="formStatus['mng_memo'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['mng_memo'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="formData.device_type == 'LANDLINE'">
                <v-col class="pt-5 pb-0 title" style="color:#999999">유선단말 설정 정보</v-col>
            </v-row>
            <v-row no-gutters v-if="formData.device_type == 'LANDLINE' && formData.sip_ext_id">
                <v-col class="pt-2 pb-0">
                    <ul>
                        <li>HOST : {{ sipHost }}</li>
                        <li>PORT : {{ sipPort }}</li>
                        <li>ID : {{ formData.sip_ext_id }}</li>
                        <li>PW : {{ formData.sip_ext_password }}</li>
                    </ul>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="formData.device_type == 'LANDLINE' && !formData.sip_ext_id">
                <v-col class="pt-2 pb-0">
                    * 변경된 목록을 저장한 후에 확정됩니다.
                </v-col>
            </v-row>

        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-6 pt-5 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapGetters} from 'vuex'
import svcStaffApi from '@/api/svc_staff'

export default {
    props: ['itemIdx'],
    data: () => ({
        formData: {
            user_name: '',
            user_mdn: '',
            mng_memo: '',
            device_type: '',
            sip_ext_id: '',
            sip_ext_password: '',
        },
        formStatus: {
            'user_name': {
                err: false,
                err_msg: '',
            },
            'user_mdn': {
                err: false,
                err_msg: '',
            },
            'mng_memo': {
                err: false,
                err_msg: '',
            }
        },
    }),
    computed: {
        ...mapGetters({
            staffList: 'svcStaff/staffList',
            landineUseYn: 'login/getLandlineUseYn',
            sipHost: 'login/getSipHost',
            sipPort: 'login/getSipPort',
        }),
    },
    mounted() {
        let staffList = this.staffList
        if (this.itemIdx >= 0 && staffList && staffList.length > this.itemIdx) {
            this.formData.user_name = staffList[this.itemIdx].user_name
            this.formData.user_mdn = staffList[this.itemIdx].user_mdn
            this.formData.mng_memo = staffList[this.itemIdx].mng_memo
            this.formData.device_type = staffList[this.itemIdx].device_type
            this.formData.sip_ext_id = staffList[this.itemIdx].sip_ext_id
            this.formData.sip_ext_password = staffList[this.itemIdx].sip_ext_password
        }
        this.$nextTick(() => {
            this.$refs.user_name.focus()
        })
    },
    methods: {
        closePopup() {
            this.$emit('cancel')
        },
        submitPopup() {
            let succ = this.checkFormValid()

            if (!succ) {
                return
            }

            this.$store.dispatch('openConfirm', {
                message: '상담원 정보를 변경하시겠습니까?',
                va: this,
                okCb: function (va) {
                    va.$store.commit('svcStaff/modifyStaff', {
                        'idx': va.itemIdx,
                        'user_name': va.formData.user_name,
                        'user_mdn': va.formData.user_mdn,
                        'mng_memo': va.formData.mng_memo,
                        'device_type': va.formData.device_type,
                    })

                    va.$store.dispatch('openAlert', {
                        va: va,
                        message: '상담원 정보가 변경 되었습니다.',
                        sub_message: '꼭! 상단의 저장버튼을 눌러야 변경사항이 저장됩니다.',
                        closeCb: function (va) {
                            va.$emit('submit')
                        }
                    })
                }
            })
        },
        checkChanged(t, focusOutFlag) {
            if (this.formStatus[t]) {
                if (this.formStatus[t].err || focusOutFlag) {
                    this.checkFormValid(t)
                }
            }
        },
        checkFormValid(t) {
            let succ = true
            if (!t || t == 'user_name') {
                if (this.formData.user_name == '') {
                    this.formStatus.user_name.err = true
                    this.formStatus.user_name.err_msg = '상담원 이름을 입력해 주세요'
                    succ = false
                } else {
                    this.formStatus.user_name.err = false
                    this.formStatus.user_name.err_msg = ''
                }
            }
            if (this.formData.device_type == 'LANDLINE') {
                this.formStatus.user_mdn.err = false
                this.formStatus.user_mdn.err_msg = ''
            } else {
                if (!t || t == 'user_mdn') {
                    if (this.formData.user_mdn == '') {
                        this.formStatus.user_mdn.err = true
                        this.formStatus.user_mdn.err_msg = '휴대폰번호를 입력해 주세요'
                        succ = false
                    } else {
                        this.formStatus.user_mdn.err = false
                        this.formStatus.user_mdn.err_msg = ''

                    }
                }
            }
            return succ
        }
    }
}
</script>
